import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { field: String }
  static targets = ["option", "selected"]

  select(event) {
    let hiddenField = document.getElementById(this.fieldValue)
    let selectedValue = hiddenField.value
    let link = event.target.closest("a")
    let id = link.id

    if (selectedValue != id) {
      console.log(this.optionTargets)
      this.optionTargets.forEach(function(item, index) {
        item.classList.remove("selected")
      });
      // this.optionTargets.every(function(option, index, array) {
      //   console.log("removing for")
      //   console.log(option)
      //   option.classList.remove("selected")
      // })
      link.classList.add("selected")
      hiddenField.value = id
      this.selectedTarget.value = id
    } else {
      link.classList.remove("selected")
      hiddenField.value = ""
    }

    const changeEvent = new Event("change")
    hiddenField.dispatchEvent(changeEvent)
  }
}