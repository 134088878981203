import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Stimulus components
import RevealController from "stimulus-reveal"
application.register("reveal", RevealController)
import ColorPickerController from "./color_picker_controller"
application.register('color-picker', ColorPickerController)

import DropdownController from "./dropdown_controller"
application.register("dropdown", DropdownController)

import SingleSelectorController from "./single_selector_controller"
application.register("single-selector", SingleSelectorController)

import TabsController from "./tabs_controller"
application.register("tabs", TabsController)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }