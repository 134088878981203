// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import { DirectUpload } from '@rails/activestorage'
import * as FilePond from "filepond"
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import "./controllers"

// ActiveStorage
ActiveStorage.start()

FilePond.registerPlugin(FilePondPluginFileValidateSize);

// FilePond
let FilePondRails = {
  directUploadUrl: null,
  input: null,
  default_options: {
    allowFileSizeValidation: true,
    maxFileSize: "5MB",
    labelMaxFileSizeExceeded: "File is too large",
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        const uploader = new DirectUpload(file, FilePondRails.directUploadUrl, {
          directUploadWillStoreFileWithXHR: (request) => {
            request.upload.addEventListener(
              'progress',
              event => progress(event.lengthComputable, event.loaded, event.total)
            )
          }
        })
        uploader.create((errorResponse, blob) => {
          if (errorResponse) {
            error(`Something went wrong: ${errorResponse}`)
          } else {
            const hiddenField = document.createElement('input')
            hiddenField.setAttribute('type', 'hidden')
            hiddenField.setAttribute('value', blob.signed_id)
            hiddenField.name = FilePondRails.input.name
            document.querySelector('form').appendChild(hiddenField)
            load(blob.signed_id)
          }
        })

        return {
          abort: () => abort()
        }
      },
      fetch: {
        url: './filepond/active_storage/fetch',
        method: 'POST',
        onload: (response) => {
          console.log(response)
          return response
        },
        ondata: (response) => {
          console.log(response)
          return response
        }
      },
      revert: {
        url: './filepond/active_storage/remove'
      },
      headers: {
        'X-CSRF-Token': document.head.querySelector("[name='csrf-token']").content
      }
    }
  },

  // Convenience method to initialize FilePond based on the way this gem expects things to work
  create: function(input) {
    FilePondRails.directUploadUrl = input.dataset.directUploadUrl
    FilePondRails.input = input

    // Initialize FilePond on our element
    return FilePond.create(input, FilePondRails.default_options)
  }
}

document.addEventListener('turbo:load', () => {
  if (document.querySelector(".filepond")) {
    FilePondRails.create(
      document.querySelector(".filepond")
    );
    console.log("loaded FilePond")
  }
  if (document.getElementById("image_preview")) {
    document.getElementById("image_preview").submit()
  }
});

// Common JS

// Closable flash messages
document.addEventListener("turbo:load", () => {
  (document.querySelectorAll(".notification .delete") || []).forEach(($delete) => {
    const $notification = $delete.parentNode;

    $delete.addEventListener("click", () => {
      $notification.parentNode.removeChild($notification);
    });
  });
});

document.addEventListener('turbo:load', () => {
  editor = document.querySelector("#editor-panel");

  (document.querySelectorAll("#editor-panel input") || []).forEach(($input) => {
    console.log("register input...")

    $input.addEventListener("change", () => {
      console.log("form changed...")
      $input.closest("form").submit();
    });
  });

  (document.querySelectorAll("#editor-panel textarea") || []).forEach(($input) => {
    console.log("register input...")

    $input.addEventListener("change", () => {
      console.log("form changed...")
      $input.closest("form").submit();
    });
  });

  (document.querySelectorAll("#editor-panel select") || []).forEach(($input) => {
    console.log("register input...")

    $input.addEventListener("change", () => {
      console.log("form changed...")
      $input.closest("form").submit();
    });
  });

  if (editor) {
    editor.submit();
  }

//   $('form input').on('change', function() {
//     $(this).closest('form').submit();
// });
});
